'use strict'

module.exports = {
    /**
     * Implicitly sets page top to 0
     * @param pageJson
     */
    name: 'pageTopFixer',
    version: 1,
    experimentalVersions: [{version: 2, experiment: 'dm_compatibilitymasterpageandpagetopfixer'}],
    exec(pageJson, _pageIdsArray, magicObject) {
        if (
            magicObject.experiment.isOpen('dm_compatibilitymasterpageandpagetopfixer') &&
            pageJson.structure &&
            pageJson.structure.type === 'Document' //Document is the type of masterpage
        ) {
            return
        }

        pageJson.structure = pageJson.structure || {}
        pageJson.structure.layout = pageJson.structure.layout || {}
        pageJson.structure.layout.y = 0
    }
}
