'use strict'

const _ = require('lodash')

/**
 * Reason for this data-fixer:
 * Both DS and viewer implement "isLandingPage()" by  isLandingPage || isMobileLandingPage
 *
 * This causes one problematic flow: {isLandingPage: true, isMobileLandingPage: false} - mobile also renders as landing page.
 *
 * This data fixer fixes 'isMobileLandingPage' for the following flow:
 * {true,false} => {true,true}
 */

/**
 * isCorruptedData function return if page dataItem has the problematic flow:
 * {isLandingPage: true, isMobileLandingPage: false}
 * @param dataItem - page dataItem
 * @returns {*|boolean}
 */
function isCorruptedData(dataItem) {
    return dataItem.isLandingPage && !dataItem.isMobileLandingPage
}

/**
 * Fixing for all pages data.
 * @param documentData
 */
function fixAllPagesDataIfNeeded(documentData) {
    _(documentData)
        .filter({type: 'Page'})
        .forEach(dataItem => {
            if (isCorruptedData(dataItem)) {
                fixLandingPageAndBumpSchemaVersion(dataItem)
            }
        })
}

function fixLandingPageAndBumpSchemaVersion(dataItem) {
    dataItem.isMobileLandingPage = true
}

function hasPagesWithSchemaVersion2(documentData) {
    const pagesWithSchemaVersion2 = _(documentData)
        .filter({type: 'Page'})
        .filter({metaData: {schemaVersion: '2.0'}})
        .value()
    return pagesWithSchemaVersion2.length > 0
}

module.exports = {
    name: 'landingPageFixer',
    disableFixerAfterFirstRun: true,
    version: 1,
    exec(pageJson) {
        const docData = pageJson.data.document_data
        const isMasterPage = pageJson.structure && pageJson.structure.type === 'Document'
        if (docData && isMasterPage) {
            const havePagesThatWereMigrated = hasPagesWithSchemaVersion2(docData)
            if (!havePagesThatWereMigrated) {
                fixAllPagesDataIfNeeded(docData)
            }
        }
    }
}
